.imagenPerfil{
    object-fit: cover!important; 
width: 100%!important; 
height:8rem!important;
max-width: 100%!important;
}

.imagenPerfil2{
    object-fit: cover!important; 
width: 100%!important; 
height:8rem!important;
max-width: 100%!important;
}

.imagenPerfil3{
    object-fit: cover!important; 
width: 100%!important; 
height:6rem!important;
max-width: 100%!important;
}



@media (min-width: 992px){
    .imagenPerfil {
        height: 16rem!important;
    }
}

@media (min-width: 992px){
    .imagenPerfil2 {
        height: 12rem!important;
    }
}

@media (min-width: 992px){
    .imagenPerfil3 {
        height: 6rem!important;
    }
}

@media (max-width:992px){
    .margenotravez{
        margin-bottom: 24px!important;
    }
}

@media (min-width: 992px){
.cosa-dopo{
    height:4.5rem!important;
    flex-direction: row!important;
}
}


    .cosa-dopo2{
        height:4.5rem!important;
        flex-direction: row!important;
    }


@media (min-width: 992px){
    .pareqImagen{
        margin-top: -4.4rem!important;
       
    }
    }

    @media (min-width: 992px){
        .nombreDpI{
            align-items: flex-start!important;
            margin-left: 1.6rem!important;
            margin-top: 0!important;
        }
        }

       
            .nombreDpI2{
                align-items: flex-start!important;
                margin-left: 1.6rem!important;
                margin-top: 0!important;
            
            }

            @media (max-width:600px){
                .nombreDpI2{
                    margin-left: 0!important;
                }
              
            }
.imagoMundi{
   /*  background-color: brown!important; */
    border-width: 4px!important;
    width: 6.4rem!important;
    height: 6.4rem!important;
    
}

.imagoMundi:hover{
    /*  background-color: brown!important; */
     border-width: 4px!important;
     width: 6.4rem!important;
     height: 6.4rem!important;
 }
 .imagoMundi2{
    background-color: gainsboro!important;
     border-width: 6px!important;
     width: 10rem!important;
      height: auto!important; 
     object-fit: content-fit!important; 
     /* width: 100%!important; 
     height:8rem!important; */
     max-height: 100%!important;
     /* object-fit: scale-down!important; */
 }
     

 .contenedor1 {
    position: relative;
    width: 100%;
    
  }

 .overlay{
    position: absolute;
  top: 7.4%;
  bottom: 0;
  left: 7.4%;
  right: 0;
  height: 85%;
  width: 85%;
  opacity: 0;
  transition: .3s ease;
  background-color: #fff;
 border-radius: 100%;
  border:none;
    /* display: none!important; */
 }

 .iconEdit {
    /* color: #004e56; */
    color: black;
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }



 .contenedor1:hover .overlay {
    opacity: 0.75;
  }

  .contenedor2{
    opacity:1!important
  }
  .contenedor2:hover .overlay {
    opacity: 1;
  }

  .iconEdit:hover{
    opacity: 1;
  }

@media (min-width: 992px){
    .direc{
       display:flex!important;
    }
}




.direc2{
    display:flex!important;
 }

 @media (max-width: 730px){
    .yanoesta{
        display:none!important;
    }
}


@media (min-width: 992px){
    .ocultero{
       margin-top:0px!important;
       padding-left:0px!important;
    }
}

.ocultero2{
   /*  margin-top:1.5rem!important; */
   padding-left:0px!important;
}

@media (min-width: 992.1px){
    .ocultero2{
        margin-top:0px!important;
       padding-left:0px!important;
       
    }

  
}
@media (max-width: 992px){
.otromarginbottom{
    margin-bottom: 16px!important;
}
}

.ocultero3{
    margin-top:0px!important;
   padding-left:0px!important;
}

.gridTabs{
    justify-content:flex-end!important;
    flex: 1 1!important;
    display: flex!important;
    margin-bottom: 16px!important;
    margin-top: 16px!important;
}
.gridTabs2{
    justify-content:center!important;
    flex: 1 1!important;
    display: flex!important;
   /*  margin-bottom: 1.6rem!important;
    margin-top: 1.6rem!important; */
}

.gridTabs3{
    justify-content:flex-end!important;
    flex: 1 1!important;
    display: flex!important;
    margin-bottom: 1.6rem!important;
    margin-top: 1.6rem!important;
}

.gridTabs4{
    justify-content:flex-end!important;
    flex: 1 1!important;
    display: flex!important;
    margin-bottom: 1.6rem!important;
    margin-top: 1.6rem!important;
}


@media (min-width: 992px){
    .gridTabs{
       margin-top:0!important;
       margin-bottom:0!important;
    }

    .gridTabs2{
        /* margin-top:8px!important;
        margin-bottom:8px!important; */
     }

     .gridTabs3{
      /*   margin-top:8px!important;
        margin-bottom:8px!important;  */
     }
     
}

@media (max-width: 991.9px){
.gridTabs4{
    /*  margin-top:8px!important; */
    margin-bottom:8px!important;  
 }
}


.tabs{
    margin-left: -0.4rem!important;
margin-right: -0.4rem!important;
min-height: 1.5rem!important;
}

.indicador-tab{
    display: flex!important;
background-color: transparent!important; 
justify-content: center!important; 
/* width: 100%!important; */
height: 100%!important;
}


  

.cadaunTab{
    padding-left: 0.6rem!important;
padding-right: 0.6rem!important; 
margin-left: 0.2rem!important;
margin-right: 0.2rem!important; 
font-weight: 600!important;
font-size: 0.875rem!important;
min-width: 2rem;
min-height: 2rem;
}

.ninos{
    width: 100%!important;
height: 100%!important;
border-radius: 9999px!important;
opacity: 0.2!important;
}

.Mui-Tabs.indicator{
    width:auto!important;
}

.pagperfil{
    padding:1.6rem!important;
}



/*  @media (min-width: 960px){

}  */

/* .curro{
    width: 28rem!important;
} */

@media (max-width: 959.9px){
    .curro{
        padding-top:1.6rem!important;
        
    }
    }

    @media (max-width: 960px){
        .alLado2{
          
            padding-top:1.6rem!important;
        }
        }

        @media (min-width: 960px){
            .nopeque{
              
                display:none!important;
            }
            }
            @media (max-width: 959.9px){
                .nogrande{
                  
                    display:none!important;
                }
                }
        

        @media (min-width: 960px){
            .alLado4{
                width:18rem!important;
               
            }
            }
            @media (min-width: 960px){
                .alLado4{
                    width:18rem!important;
                   
                }
                }

                @media (max-width: 959.9px){
                    .alLado4{
                        padding-top:1.6rem!important;
                       
                    }
                    }

.w-full{
    width:100%!important;
}


@media (min-width: 960px){
.cuadro{
    display:flex!important;
}
}


@media (min-width: 600px) and (max-width: 959.9px){
    .medquer{
        display:flex!important;
    }
    }

@media (min-width: 960px){
.alLado{
    width:18rem!important;
    margin-right: 1.6rem!important;
}
}



/* .alLado3{
    min-width: 25rem!important;
    
} */

/* @media (min-width: 643px) and (max-width: 959.9px){
    .tutti2{
        width:40rem!important;
    }
    } */
/*     @media (min-width: 600px){
.alLado3{
    width:28.42rem!important;
}
    } */

@media (min-width: 960px){
    .alLado3{
        width:28.42rem!important;
        margin-right: 1.6rem!important;
    }
    }

    @media (min-width: 960px){
        .alLado6{
         
            margin-right: 1.6rem!important;
        }
        }

.completo{
/*     background-color:#DFE0DF!important ; */
     background-color:rgb(241, 245, 249)!important ;
/* background-color: #E9EAED; */
   /*  background-color:#edeae5!important ; */
}

/* .completo3{
  
         background-color:rgba(211, 211, 211,0.2)!important ;
  
    } */

.completo2{
    /*     background-color:#DFE0DF!important ; */
       /*   background-color:rgb(241, 245, 249)!important ; */
    /* background-color: #E9EAED; */
         background-color:#edeae5!important ; 
    }

.noHover:hover{
    background-color: transparent!important;

}





 


/*   .rounded{
    border-radius: 9999px!important;
  } */

  @media (max-width: 600px){
  .ancho2{
    width: 80% !important;
  }
}

/*   .mismaLinea{
    display: inline !important;

  }

  .mismaLinea :nth-last-child(1) {
    display: inline!important;
  } */
.hoverColor{
    cursor: pointer!important;
    font-weight: 400!important;
    
/*     width: 60px!important;
    justify-content: end!important; */
}
  .hoverColor:hover{
    color: #004e56!important;
    font-weight: 400!important;
  }

/*   @media (max-width: 600px){
    .DialogChico{
        padding-right:24px!important;
        padding-left:24px!important;
    }
    } */


    .table2{
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        display: flex!important;
        min-width: 117px!important;
        justify-content: flex-end!important;
    }

    .table22{
        padding-left: 0rem!important;
        padding-right: 0rem!important;
        display: flex!important;
        min-width: 120px!important;
        justify-content: flex-end!important;
    }


    .table3{
        display: flex!important;
    }

   /* .table4{
       
        display: block!important;
    }  */

    .table5{
        display: flex!important;
         justify-content: space-between!important;
    }


    
    .tables{
        width: 100% !important;
        margin: 0 !important;
        
    }


    /* @media (max-width:710px)  { */
        .margenIzq2{
            margin-left: 0px!important;
    
        }
    /* } */
    
   /*  @media (max-width:710px)  { */
        .margenDer2{
            margin-right: 0px!important;
            
        }
   /*  } */

  
    
    @media (max-width:610px)   {
        .margenDer2{
            width: 180px!important;
        }
    } 
    @media (max-width:500px)   {
        .margenDer2{
            width: 150px!important;
        }
    } 

    @media (max-width:600px)   {
        .vere{
            width: 100%!important;
        }
    } 

    .saco-detalles{
        color:#a6a8a3;
        text-decoration: none;
        overflow-wrap: anywhere;
        display:flex;
        justify-content: flex-start;
    }

    .saco-detalles:hover{

        text-decoration: underline;
    }

    .veamos-sip{
        overflow-wrap: anywhere!important;
    }
  
    
    .butt2:hover {
        background-color: rgba(30, 32, 34, 0.04)!important;
        
    }
    
    .linkeofoot:hover{
        cursor: pointer!important;
       
    }

    .color-Link:hover{
        fill: #007BB5!important;
       
    }

    .color-Insta:hover{
        fill: #E4405F!important;
       
    }

    .color-Twitter:hover{
        fill: #1DA1F2!important;
       
    }

    .color-Facebook:hover{
        fill: #4267B2!important;
       
    }

    .imagen-avatar{
        height:200px;
        width:200px;
        object-fit:cover;
        margin:16px;
        
    }

    .botonhover:hover{
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    .botonhover2:hover{
        background-color: gainsboro !important;
    }

    .pruee{
         position: absolute!important; 
         /* top:+70px!important; */
         top:+20px!important;
         right:+7%!important;
    }

    @media (min-width: 992px){
    .pruee{
       /*  margin-top: -4 rem!important; */
        position: relative!important;
        top:-250px!important;
        /* top:-250px!important; */
        /* top:-200px!important; */
        /* left:-80px!important; */
        left:-40px!important;
    /*     left:-30px!important; */
    }
    }

    @media (max-width: 992px){
        .cierremarg{
            margin-bottom: 0!important;
        }
    }
    
   
    @media (max-width:980px)   {
        .radiofondo{
            width: 33%!important;
        }
    }
    
    @media (max-width:780px)   {
        .radiofondo{
            width: 49%!important;
        }
    }

    @media (max-width:500px){
        .radiofondo{
            width: 50%!important;
            margin-left: 0px!important;
            margin-right: 0px!important;
        }
    }
    
.alLado4{
    min-width: 250px!important;
}
    
  @media (min-width: 600px) and (max-width: 959.9px){
        .medquer2{
            padding-top:0px!important;
            padding-left: 1.6rem!important;
            
        }
        } 

        @media (min-width: 600px) and (max-width: 959.9px){
            .medquer3{
                height:100%!important;
            
                
            }
            } 

            @media (min-width: 643px) and (max-width: 959.9px){
                .medquer4{
                    display:flex!important;
                }
                }
                @media (min-width: 643px) and (max-width: 959.9px){
                .puente{
                    padding-right: 1.6rem!important;
                }
            }

                @media (min-width: 643px) and (max-width: 959.9px){
                    .medquer5{
                        padding-left:1.6rem!important;
                        /* width: 28.42rem!important; */
                    }
                    }

                    @media (min-width: 643px) and (max-width: 959.9px){
                        .medquer5{
                            padding-top:0px!important;
                            /* width: 28.42rem!important; */
                        }
                        }
    


               /*      @media (min-width: 800px) and (max-width: 959.9px){
                        .alLado3{
                            width: 28.42rem!important;

                        }
                        } */

                        .bloque1{
                          
                            /* width: 100% !important; */
                            text-align: center!important;
                        }

                        .bloque2{
                          
                          /*   width: 50% !important; */
                            text-align: center!important;
                        }

                        .bloque3{
                           
                            /* width: 33% !important; */
                            text-align: center!important;
                        }

                        .bloque4{
                        
                            /* width: 25% !important; */
                             text-align: center!important;
                        }

                        .table6{
flex-basis: 50%!important;
                        }
 @media (min-width: 643px) and (max-width: 695px){
.mediaBen3{
    width: 70% !important;
}
} 


@media (min-width: 643px) {
.industriaArr{
  
    width: 50% !important;
}
}

@media (min-width: 643px) {
    .industriaArr2{
      
        width: 25% !important;
    }
    }

@media (min-width: 600px) {
.largo{
width:50%!important
}
}

/* @media (min-width: 600px) and (max-width:960px) {
.unique2{
    width: 50%!important;
}
} */



@media (min-width: 600px) and (max-width:960px) {
    .tutti2{
        min-width: 57%!important;
    }
    } 

    .elimBot{
        padding-bottom: 8px!important;
    }

    @media (min-width: 600px) and (max-width: 959.9px){
        .cuadro3{
            padding-left: 0px!important;
            padding-top: 1.6rem!important;
        }
    }
    
    .colorYPeso{
        font-weight: 600!important;
        color: #004e56 !important;
        line-height: 1.5!important;
    }

    .colorYPeso2{
        font-weight: 600!important;
        color: #a6a8a3 !important;
        background-color: #a6a8a3!important;
        line-height: 1.5!important;
    }

    .industriasVistaE{
        font-weight: 600;
       /*  color: #004e56; */
    }

    .industriasVistaE2{
        font-weight: 600;
         color: #004e56; 
         line-height: 1.5;
    }

    @media (min-width: 992px) {

    
    .ocultarContacto{
        display:none!important
    }


}

@media (max-width: 991.9px) {

    
    .ocultarContacto2{
        display:none!important
    }


}

.pagperfil2{
    padding:1.6rem 4vw!important;
    
}

.myCustomScroll::-webkit-scrollbar{
    width: '0.4em'
  }
  
  .myCustomScroll::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00)
  }
  
  .myCustomScroll::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,.1);
    outline: 1px solid slategrey
  }

  .desabilitadoBot.Mui-disabled{
    background-color:rgba(0, 0, 0, 0.12)!important;
  }

  @media (max-width:500px){
    .ocultoChFo{
        display:none!important;
      
    }

  /*   .ocultoChFo2{
       
        margin-left: 0px!important;
    } */
  }
  

  .overlay2{
    position: absolute;
  top: 7.4%;
  bottom: 0;
  left: 7.4%;
  right: 0;
  height: 85%;
  width: 85%;
  opacity: 1;
  
  background-color: #fff;
 border-radius: 100%;
  border:none;
 
 }


 @media screen and (max-width: 600px) {
    .cosadopodopo{
    padding-left: 0px!important;
    padding-right: 8px!important;
    }

    .objetivoBusquedaEmpresa{
        left: calc(0rem + 6.4rem + 0rem + 14px) !important;
    }
 
    .objetivoBusquedaBeneficios{
        left: calc(0rem + 6.4rem + 0rem + 14px + 162.04px + 4px) !important;
    }
 }    


 @media screen and (max-width: 600px) {

    
    .pruebequeTypo{
        font-size: 12px!important;
        line-height: 24px!important;
        margin-top: auto!important;
        margin-bottom: 0px!important;
    }
    }